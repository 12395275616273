<template>
  <div class="page">
    <section>
      <!-- 标题 -->
      <span class="title">发布</span>
      <div class="content">
        <div class="drafts" @click="$router.push('personalCenter?index=1')">
          <img src="@image/drafts.png" alt="" />
          草稿箱
        </div>

        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 发布说说 -->
          <el-tab-pane
            label="发布说说"
            name="tab1"
            v-if="!id || (id && 'tab1' == activeName)"
          >
            <div class="speakDiv">
              <el-input
                autocomplete="off"
                type="textarea"
                placeholder="再小的想法，都值得被记忆......"
                v-model="talk"
                class="postTalkInput"
                style="width: 1100px; height: 162px"
              >
              </el-input>
              <!-- 上传按钮 -->
              <div class="wrap" :style="{ display: display1 }">
                <el-upload
                  :class="{ hide: exceed1 }"
                  :limit="9"
                  ref="upload1"
                  multiple
                  :headers="{ token: token }"
                  :action="$http.baseURL + '/dkt/other/upload'"
                  name="files"
                  :on-success="succseeFile1"
                  :on-remove="removeFile1"
                  list-type="picture-card"
                  :before-upload="progressImg1"
                  :file-list="filelists1"
                  class="fabu"
                >
                  <template #default>
                    <i class="iconfont icon-test"></i>
                    <span>配图（选填)</span>
                  </template>
                  <div
                    slot="file"
                    slot-scope="{ file }"
                    style="width: 100%; height: 100%"
                  >
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      style="object-fit: cover"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove('upload1', file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>

                <div class="desc" v-if="speakDivDescShow">
                  <img
                    @click="speakDivDescShow = false"
                    class="close"
                    src="@image/close.png"
                  />
                  <img src="@image/desc.png" alt="" />
                  用户上传内容确保合理合法，平台规避风险责任（若您公开发布的信息中包含他人个人信息安全的（如：姓名，手机号，家庭居住地址等敏感信息），请在在上传发布之前，注意遮挡避免个人信息曝光，您需确保为法律法规允许使用或已获得合法授权。若有违规行为经平台审核发现则永久禁言。）
                </div>
              </div>
            </div>

            <!-- 按钮 -->
            <div class="btn">
              <el-button
                class="button plain"
                :loading="isBtn"
                plain
                @click="speakDraft()"
                >存入草稿箱</el-button
              >
              <el-button
                class="button"
                :loading="isBtn"
                @click="findInfo"
                type="primary"
                >发布</el-button
              >
            </div>
          </el-tab-pane>

          <!-- 病例分享 -->
          <el-tab-pane
            label="病例分享"
            name="tab2"
            v-if="!id || (id && 'tab2' == activeName)"
          >
            <div class="caseDiv">
              <el-input
                v-model="caseAnalysis"
                placeholder="请输入标题（10-40个字）"
                class="caseAnalysis"
                autocomplete="off"
              >
              </el-input>
              <div class="richText">
                <quill-editor
                  v-model="caseSubtitle"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @change="onEditorChange($event)"
                >
                </quill-editor>
              </div>

              <div class="desc" v-if="caseDivDescShow">
                <img
                  @click="caseDivDescShow = false"
                  class="close"
                  src="@image/close.png"
                />
                <img src="@image/desc.png" alt="" />
                用户上传内容确保合理合法，平台规避风险责任（若您公开发布的信息中包含他人个人信息安全的（如：姓名，手机号，家庭居住地址等敏感信息），请在在上传发布之前，注意遮挡避免个人信息曝光，您需确保为法律法规允许使用或已获得合法授权。若有违规行为经平台审核发现则永久禁言。）
              </div>

              <!-- 按钮 -->
              <div class="btn">
                <el-button
                  class="button plain"
                  :loading="isBtn"
                  plain
                  @click="caseAnalysisDraft()"
                  >存入草稿箱</el-button
                >
                <el-button
                  class="button"
                  :loading="isBtn"
                  @click="findInfo1"
                  type="primary"
                  >发布</el-button
                >
              </div>
            </div>
          </el-tab-pane>

          <!-- 学术视频 -->
          <el-tab-pane
            label="学术视频"
            name="tab3"
            v-if="!id || (id && 'tab3' == activeName)"
          >
            <div class="speakDiv scienceVideo">
              <el-input
                autocomplete="off"
                v-model="popularTitle"
                placeholder="请输入标题（10-40个字）"
                class="popularTitle"
              >
              </el-input>
              <div class="videoOne">
                <el-upload
                  :action="$http.baseURL + '/dkt/other/upload'"
                  :headers="{ token: token }"
                  ref="uploadVideo"
                  :disabled="disState"
                  :class="{ hide: disState }"
                  :limit="1"
                  name="files"
                  :on-success="succseeVideo"
                  :before-upload="progressVideo"
                  :on-error="errorVideo"
                  :file-list="filevideo"
                  list-type="picture-card"
                  class="video_name"
                  :on-exceed="onExceed"
                >
                  <i class="iconfont icon-test" style="font-size: 39px"></i>
                  <span class="uploading">上传视频</span>
                  <div
                    slot="file"
                    slot-scope="{ file }"
                    style="height: 100%"
                    v-loading="loading"
                  >
                    <video
                      controls
                      id="videoPlayerNew"
                      ref="videoRef"
                      :src="popularVideo"
                      style="height: 100%; width: 100%"
                    ></video>
                    <div
                      class="videoDel-box"
                      @click="handleRemove('uploadVideo', file)"
                    >
                      <i class="videoDel el-icon-delete-solid"></i>
                    </div>
                  </div>
                </el-upload>

                <el-upload
                  :limit="1"
                  ref="upload3"
                  multiple
                  :headers="{ token: token }"
                  :action="$http.baseURL + '/dkt/other/upload'"
                  name="files"
                  :disabled="disStates"
                  :class="{ hide: disStates }"
                  :on-success="succseeImg"
                  :before-upload="progressImg"
                  :on-error="errorImg"
                  :file-list="filelists3"
                  list-type="picture-card"
                  class="videoImg"
                >
                  <template #default>
                    <i class="iconfont icon-test" style="font-size: 39px"></i>
                    <span>上传封面</span>
                  </template>
                  <div slot="file" slot-scope="{ file }" style="height: 150px">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove('upload3', file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </div>
              <el-input
                autocomplete="off"
                type="textarea"
                :rows="3"
                v-model="popularSubtitle"
                placeholder="输入视频内容"
                class="popularScienceVideo"
              >
              </el-input>
              <div class="desc1" v-if="speakDivThreeDescShow">
                <img
                  @click="speakDivThreeDescShow = false"
                  class="close"
                  src="@image/close.png"
                />
                <img src="@image/desc.png" alt="" />
                用户上传内容确保合理合法，平台规避风险责任（若您公开发布的信息中包含他人个人信息安全的（如：姓名，手机号，家庭居住地址等敏感信息），请在在上传发布之前，注意遮挡避免个人信息曝光，您需确保为法律法规允许使用或已获得合法授权。若有违规行为经平台审核发现则永久禁言。）
              </div>

              <div class="btn">
                <el-button
                  class="button plain"
                  :loading="isBtn"
                  plain
                  @click="scienceDraft()"
                  >存入草稿箱</el-button
                >
                <el-button
                  class="button"
                  :loading="isBtn"
                  @click="popularScience"
                  type="primary"
                  >发布</el-button
                >
              </div>
            </div>
          </el-tab-pane>

          <!-- 指南解析 -->
          <el-tab-pane
            label="指南解析"
            name="tab4"
            v-if="!id || (id && 'tab4' == activeName)"
          >
            <el-input
              autocomplete="off"
              v-model="entryTitle"
              class="guide-title"
              placeholder="请输入标题"
            ></el-input>

            <div class="richText">
              <quill-editor
                v-model="guideSubtitle"
                ref="myQuillEditor"
                :options="guideOption"
              >
              </quill-editor>
            </div>
            <!-- 上传附件 -->
            <div class="yourAnswer">
              <div class="answer">
                <span class="answerTitle">【您还可以上传文档】</span>
                <span class="annotation">(注：请上传pdf或者word)</span>
                <img
                  @click="upload_img"
                  class="upload_img"
                  src="@image/upload.png"
                  alt=""
                />
              </div>

              <!-- 上传word或者pdf -->
              <el-upload
                class="uploadaAnnex"
                accept=".docx,.pdf"
                :headers="{ token: token }"
                :action="$http.baseURL + '/dkt/discover/upload'"
                :on-preview="handlePreview"
                name="file"
                :file-list="annexFileList"
                multiple
                :on-success="successAnnexFile"
                :before-upload="beforeAnnexFile"
                :on-remove="removeAnnexFile"
              >
                <img
                  style="display: none"
                  ref="upload_img"
                  src="@image/upload.png"
                  alt=""
                />
              </el-upload>
            </div>

            <!-- 疾病 -->
            <div class="yourAnswer">
              <div class="answer">
                <span class="answerTitle">【疾病】</span>
                <span class="annotation"
                  >(注：所属疾病至少1-2个，用@分割，没有则填内容)</span
                >
              </div>
              <el-input
                autocomplete="off"
                v-model="disease"
                placeholder="请输入疾病名称"
                class="illness"
              ></el-input>
            </div>
            <!-- 搜索词 -->
            <div class="yourAnswer">
              <div class="answer">
                <span class="answerTitle">【搜索词】</span>
                <span class="annotation"
                  >(注：至少1个关键词、1个意图词，用@分割，权重高的排前面)</span
                >
              </div>
              <el-input
                autocomplete="off"
                v-model="search"
                placeholder="请输入搜索词"
                class="illness"
              ></el-input>
            </div>

            <div class="yourAnswer">
              <div class="desc1" v-if="speakDivThreeDescShow">
                <img
                  @click="speakDivThreeDescShow = false"
                  class="close"
                  src="@image/close.png"
                />
                <img src="@image/desc.png" alt="" />
                用户上传内容确保合理合法，平台规避风险责任（若您公开发布的信息中包含他人个人信息安全的（如：姓名，手机号，家庭居住地址等敏感信息），请在在上传发布之前，注意遮挡避免个人信息曝光，您需确保为法律法规允许使用或已获得合法授权。若有违规行为经平台审核发现则永久禁言。）
              </div>
              <div class="btn">
                <el-button
                  class="button plain"
                  :loading="isBtn"
                  plain
                  @click="entryDraft"
                  >存入草稿箱</el-button
                >
                <el-button
                  class="button"
                  :loading="isBtn"
                  @click="entry"
                  type="primary"
                  >发布</el-button
                >
              </div>
            </div>
          </el-tab-pane>

          <!-- 发布论文 -->
          <el-tab-pane
            label="发布论文"
            name="tab11"
            v-if="!id || (id && 'tab11' == activeName)"
          >
            <el-input
              autocomplete="off"
              v-model="paperTitle"
              class="guide-title"
              placeholder="请输入标题"
            ></el-input>
            <div class="richText">
              <quill-editor
                v-model="paperContent"
                ref="myQuillEditor"
                :options="guideOption"
              >
              </quill-editor>
            </div>

            <!-- 上传附件 -->
            <div class="yourAnswer">
              <div class="answer">
                <span class="answerTitle">【您还可以上传文档】</span>
                <span class="annotation">(注：请上传pdf或者word)</span>
                <img
                  @click="upload_guide"
                  class="upload_img"
                  src="@image/upload.png"
                  alt=""
                />
              </div>

              <!-- 上传word或者pdf -->
              <el-upload
                class="uploadaAnnex"
                accept=".docx,.pdf"
                :headers="{ token: token }"
                :action="$http.baseURL + '/dkt/discover/upload'"
                :on-preview="paperPreview"
                name="file"
                :file-list="paperFileList"
                multiple
                :on-success="successPaperFile"
                :before-upload="beforePaperFile"
                :on-remove="removePaperFile"
              >
                <img
                  style="display: none"
                  ref="upload_guide"
                  src="@image/upload.png"
                  alt=""
                />
              </el-upload>
            </div>

            <!-- 是否同意授权书 -->
            <div class="agreement">
              <span @click="empowerVisible = true" style="cursor: pointer"
                >是否同意<font color="#2470FF">《作品授权书》</font>:</span
              >
              <el-radio v-model="agreeRadio" label="1">是</el-radio>
              <el-radio v-model="agreeRadio" label="0">否</el-radio>
            </div>

            <!-- 原创声明 -->
            <div class="original">
              <el-checkbox v-model="originalChecked"></el-checkbox>
              <span
                @click="originalVisible = true"
                style="color: #1cd6b9; cursor: pointer"
              >
                作品原创声明
              </span>
            </div>

            <div class="desc1" v-if="speakDivThreeDescShow">
              <img
                @click="speakDivThreeDescShow = false"
                class="close"
                src="@image/close.png"
              />
              <img src="@image/desc.png" alt="" />
              用户上传内容确保合理合法，平台规避风险责任（若您公开发布的信息中包含他人个人信息安全的（如：姓名，手机号，家庭居住地址等敏感信息），请在在上传发布之前，注意遮挡避免个人信息曝光，您需确保为法律法规允许使用或已获得合法授权。若有违规行为经平台审核发现则永久禁言。）
            </div>

            <div class="btn paperBtn">
              <el-button
                class="button plain"
                :loading="isBtn"
                plain
                @click="paperDarft"
                >存入草稿箱</el-button
              >
              <el-button
                @click="releasePaper"
                class="button"
                :loading="isBtn"
                type="primary"
                >发布</el-button
              >
            </div>
          </el-tab-pane>

          <!-- 发布问答 -->
          <el-tab-pane
            label="发布问答"
            name="tab5"
            v-if="!id || (id && 'tab5' == activeName)"
          >
            <div class="speakDiv">
              <el-input
                v-model="talkTitle"
                placeholder="请输入问答标题（5-30个字）"
                autocomplete="off"
              ></el-input>
              <el-input
                autocomplete="off"
                type="textarea"
                :rows="3"
                v-model="talkSubtitle"
                placeholder="添加描述（选填）"
                class="describe"
                style="height: 106px !important"
              >
              </el-input>
              <!-- 上传按钮 -->
              <div class="bottomBox" :style="{ display: display2 }">
                <el-upload
                  :class="{ hide: exceed2 }"
                  :headers="{ token: token }"
                  :limit="9"
                  ref="upload5"
                  multiple
                  :action="$http.baseURL + '/dkt/other/upload'"
                  name="files"
                  :on-success="succseeFile2"
                  :before-upload="progressImg2"
                  :on-remove="removeFile2"
                  :file-list="filelists5"
                  list-type="picture-card"
                  style="margin-left: 20px"
                >
                  <template #default>
                    <i class="iconfont icon-test" style="font-size: 50px"></i>
                    <span style="font-size: 14px; margin-top: 20px"
                      >配图（选填)</span
                    >
                  </template>
                  <div
                    slot="file"
                    slot-scope="{ file }"
                    style="width: 100%; height: 100%"
                  >
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      style="object-fit: cover"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove('upload5', file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <div class="desc" v-if="speakDivfourDescShow">
                  <img
                    @click="speakDivfourDescShow = false"
                    class="close"
                    src="@image/close.png"
                  />
                  <img src="@image/desc.png" alt="" />
                  用户上传内容确保合理合法，平台规避风险责任（若您公开发布的信息中包含他人个人信息安全的（如：姓名，手机号，家庭居住地址等敏感信息），请在在上传发布之前，注意遮挡避免个人信息曝光，您需确保为法律法规允许使用或已获得合法授权。若有违规行为经平台审核发现则永久禁言。）
                </div>
              </div>
              <div class="btn">
                <el-button
                  class="button plain"
                  :loading="isBtn"
                  plain
                  @click="talkDarft"
                  >存入草稿箱</el-button
                >
                <el-button
                  class="button"
                  :loading="isBtn"
                  @click="talkAbout"
                  type="primary"
                  >发布</el-button
                >
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>

    <!-- 预览大图对话框 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="imgView" alt="" />
    </el-dialog>

    <!-- 学习认证对话框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleTwo"
      width="560px"
      :before-close="handleClose"
    >
      <span>学习请先进行身份认证</span>
      <div class="popupsButton">
        <span
          @click="dialogVisibleTwo = !dialogVisibleTwo"
          class="popupsJudgment"
          >取消</span
        >
        <span @click="go" class="popupsJudgment">认证</span>
      </div>
    </el-dialog>

    <!-- 作品授权书 -->
    <el-dialog
      class="empower"
      title="作品授权书"
      :visible.sync="empowerVisible"
      width="40%"
    >
      <img @click="empowerVisible = false" src="@image/closebill.png" alt="" />
      <h4>作品授权书</h4>

      <div class="word" v-html="empowerContent"></div>
    </el-dialog>

    <!-- 原创声明 -->
    <el-dialog
      class="original"
      title="原创声明"
      :visible.sync="originalVisible"
      width="40%"
    >
      <img @click="originalVisible = false" src="@image/closebill.png" alt="" />
      <h4>原创声明</h4>
      <div class="word" v-html="originalContent"></div>
    </el-dialog>

    <!-- 重复上传确认框 -->
    <el-dialog
      class="repeat_confirm"
      title="提示"
      :visible.sync="repeatVisible"
      width="300px"
    >
      <span>请确认该文件是否为重复上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="repeatCon(0)">取 消</el-button>
        <el-button @click="repeatCon(1)" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      activeName: "tab1", // 默认选中的标签页
      agreeRadio: "", // 同意授权书
      annexFileList: [], // 指南解析附件回显数据
      annexFile: [], // 指南解析附件上传数据
      caseAnalysis: "", // 病例分享标题
      caseSubtitle: "", // 病例分享内容
      dialogVisible: false, // 控制图片预览对话框的显示
      dialogVisibleTwo: false, // 控制身份认证对话框的显示
      disabled: false, // 控制上传图片的删除按钮显示
      disease: "", // 指南解析疾病名称
      disState: false, // 学术视频上传视频是否禁用
      disStates: false, // 学术视频上传视频封面是否禁用
      duration: 0,
      empowerVisible: false, // 授权协议对话框
      empowerContent: "",
      entryTitle: "", // 指南解析标题
      // 富文本的配置项
      editorOption: {
        placeholder: "请开始你的创作",
      },
      filelists1: [], // 发布说说图片回显数据
      filevideo: [], // 学术视频上传视频列表
      filelists3: [], // 学术视频上传封面列表
      filelists5: [], // 问答
      guideSubtitle: "", // 指南解析内容
      guideOption: {
        placeholder: "请输入",
      }, // 指南解析富文本的配置项
      loading: false, // loading层的显示
      originalChecked: "", // 发布论文原创声明
      originalVisible: false, // 原创声明对话框
      originalContent: "",
      paperTitle: "", // 发布论文标题
      paperContent: "", // 发布论文的内容
      paperFileList: [], // 发布论文附件回显数据
      paperFile: [], // 发布论文附件上传数据
      popularVideo: "", // 学术视频视频回显路径
      popularTitle: "", // 学术视频标题
      popularSubtitle: "", // 学术视频内容
      id: "", // 点击编辑回显的id
      isBtn: false, // 按钮是否加载中状态
      isShow: true, // 增加科普贡献的选中设置
      imgView: "", // 预览图片
      search: "", // 指南解析搜索词
      speakDivDescShow: true, // 发布说说提示信息的显示
      caseDivDescShow: true, // 病例分享提示信息的显示
      speakDivThreeDescShow: true, // 学术视频提示信息的显示
      speakDivfourDescShow: true, // 发布问答提示信息的显示
      talk: "", // 发布说说内容
      talkTitle: "", // 发布问答标题
      talkSubtitle: "", // 发布问答描述
      talkImage: [], // 发布说说图片
      token: localStorage.getItem("token") || "", // 登录token
      display1: "flex",
      display2: "flex",
      exceed1: false,
      exceed2: false,
      repeatVisible: false,
    };
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  mounted() {
    this.getAgreement();
    this.$route.query.id ? (this.id = this.$route.query.id) : (this.id = "");
    let type = this.$route.query.type;
    if (type) this.activeName = "tab" + type;
    if (this.$route.query.from == "creation") {
      this.getDetail();
    }
    if (this.$route.query.from == "draft") {
      this.draftInfo();
    }
  },

  methods: {
    go() {
      this.dialogVisible = false;
      this.$router.push({
        path: "/authentication",
      });
    },
    handleClose(done) {
      done();
    },

    // 原创声明+作品授权书
    async getAgreement() {
      let { code, data, message } = await this.$loginApi.getAgreement();
      this.loading = false;
      if (code == 200) {
        this.empowerContent = data.sqs;
        this.originalContent = data.ycsm;
      } else {
        this.$message.error(message);
      }
    },

    // 获取详情
    async getDetail() {
      this.xin.isLoadding(true);
      let res = await this.$findApi.findDetail({
        discover_id: this.id,
      });

      if (res.code == 200) {
        let flag = this.$route.query.type;
        let imgs = res.data.info.images ? JSON.parse(res.data.info.images) : [];
        // 发布说说
        if (flag == 1) {
          this.talk = res.data.info.content;
          for (let i in imgs) {
            let list = {
              url: imgs[i],
            };
            this.filelists1.push(list);
            this.talkImage.push(list);
          }
          if (this.talkImage.length) {
            this.display1 = "block";
          }
        } else if (flag == 2) {
          // 病例分享
          this.caseAnalysis = res.data.info.title;
          this.caseSubtitle = res.data.info.content;
        } else if (flag == 3) {
          // 学术视频
          this.popularTitle = res.data.info.title;
          this.popularSubtitle = res.data.info.content;
          // 视频封面
          this.filelists3 = [
            {
              url: res.data.info.video_img,
            },
          ];
          this.videoImg = res.data.info.video_img;
          this.disStates = true;
          // 视频
          this.filevideo = [
            {
              url: res.data.info.video,
            },
          ];
          this.popularVideo = res.data.info.video;
          // this.howLong();
          this.disState = true;
        } else if (flag == 4) {
          // 指南解析
          this.entryTitle = res.data.info.title;
          this.guideSubtitle = res.data.info.content;
          this.disease = res.data.info.disease;
          this.search = res.data.info.search;
          // 上传附件回显
          let file_path = res.data.info.file_path_new;
          if (file_path) {
            Object.keys(file_path).forEach((key) => {
              this.annexFile.push({
                file_path: [key],
                file_name: [file_path[key]],
              });
              this.annexFileList.push({
                url: key,
                name: file_path[key],
              });
            });
          }
        } else if (flag == 5) {
          // 发布问答
          this.talkTitle = res.data.info.title;
          this.talkSubtitle = res.data.info.content;
          for (let i in imgs) {
            let list = {
              url: imgs[i],
            };
            this.filelists5.push(list);
            this.talkImage.push(list);
          }
          if (this.talkImage.length > 0) {
            this.display2 = "block";
          }
        } else if (flag == 11) {
          // 发布论文
          this.paperTitle = res.data.info.title;
          this.paperContent = res.data.info.content;
          this.agreeRadio = "" + res.data.info.sqs;
          this.originalChecked = true;

          // 上传附件回显
          let file_path = res.data.info.file_path_new;
          if (file_path) {
            Object.keys(file_path).forEach((key) => {
              this.paperFile.push({
                file_path: [key],
                file_name: [file_path[key]],
              });
              this.paperFileList.push({
                url: key,
                name: file_path[key],
              });
            });
          }
        }
      } else {
        this.$message.error(res.message);
      }
      this.xin.isLoadding();
    },

    //视屏封面
    succseeImg(res) {
      if (res.code == 200) {
        this.disStates = true;
        this.videoImg = res.data;
      } else {
        this.$message({
          message: "视频封面不能为空",
          type: "error",
        });
      }
    },

    // 视频
    succseeVideo(response, file, fileList) {
      //视频
      if (response.code == 200) {
        this.popularVideo = response.data;
        this.loading = false;
        this.$forceUpdate();
        this.howLong();
      } else {
        this.$message({
          message: response.message,
          type: "error",
        });
      }
      // this.$refs.uploadVideo.clearFiles()
    },

    howLong() {
      if (this.popularVideo) {
        let audioContext = document.getElementById("videoPlayerNew");
        audioContext.onloadedmetadata = () => {
          this.duration = this.xin.formatSeconds(audioContext.duration);
        };
      }
    },

    errorImg() {
      this.disStates = false;
    },

    errorVideo() {
      this.$message({
        message: "上传文件最大限制20M",
        type: "error",
      });
      this.disState = false;
      this.loading = false;
      this.$refs.uploadVideo.clearFiles();
    },

    //  发布说说
    progressImg1(file) {
      if (file.type.split("/")[0] != "image") {
        this.$message({
          message: "图片格式不正确",
          type: "error",
        });
        return false;
      }
      this.display1 = "block";
      // this.disStates = true;
    },

    // 发布问答
    progressImg2(file) {
      if (file.type.split("/")[0] != "image") {
        this.$message({
          message: "图片格式不正确",
          type: "error",
        });
        return false;
      }
      this.display2 = "block";
      // this.disStates = true;
    },

    // 学术视频
    progressImg(file) {
      if (file.type.split("/")[0] != "image") {
        this.$message({
          message: "图片格式不正确",
          type: "error",
        });
        return false;
      }
      this.disStates = true;
      this.speakDivDescShow = false;
      this.speakDivfourDescShow = false;
    },

    progressVideo(file) {
      if (["video/x-msvideo", "video/mp4"].indexOf(file.type) == -1) {
        this.$message({
          message: "视频格式为mp4或x-msvideo",
          type: "error",
        });
        return false;
      }
      this.loading = true;
      this.disState = true;
    },

    // 发布说说文件上传
    async succseeFile1(response, file, fileList) {
      if (fileList.length == 9) {
        this.exceed1 = true;
      }
      if (response.code == 200) {
        this.talkImage.push({
          url: response.data,
          uid: file.uid,
        });
      } else {
        this.$message.error(response.message);
      }
    },
    // 删除说说图片
    removeFile1(file, fileList) {
      setTimeout(() => {
        this.exceed1 = false;
        if (fileList == 0) {
          this.display1 = "flex";
        }
      }, 1000);
    },

    // 发布问答文件上传
    async succseeFile2(response, file, fileList) {
      if (fileList.length == 9) {
        this.exceed2 = true;
      }
      if (response.code == 200) {
        this.talkImage.push({
          url: response.data,
          uid: file.uid,
        });
      } else {
        this.$message.error(response.message);
      }
    },

    // 删除说说图片
    removeFile2(file, fileList) {
      setTimeout(() => {
        this.exceed2 = false;
        if (fileList == 0) {
          this.display2 = "flex";
        }
      }, 1000);
    },

    // 指南解析附件上传
    upload_img() {
      this.$refs.upload_img.click();
    },
    successAnnexFile(response) {
      if (response.code == 200) {
        this.annexFile.push(response.data);
        this.$message.success(response.message);
      } else {
        this.$message.error(response.message);
      }
    },
    handlePreview(file) {
      if (file.response) {
        window.open(file.response.data[0]);
      } else {
        window.open(file.url);
      }
    },

    removeAnnexFile(file, fileList) {
      this.annexFile = fileList.map((item) => {
        return { file_path: [item.url], file_name: [item.name] };
      });
    },
    beforeAnnexFile(file) {
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      if (type != "docx" && type != "pdf") {
        this.$message.error("上传文件的类型为word或者pdf");
        return;
      }
    },

    // 发布论文附件上传
    upload_guide() {
      this.$refs.upload_guide.click();
    },

    // 成功上传
    successPaperFile(response) {
      let { code, data } = response;
      // 重复判断
      if (code == 200) {
        let file_name = data.file_name[0];
        let size = data.size[0];
        if (!this.paperFile.length) {
          this.paperFile.push(response.data);
          this.$message.success(response.message);
        } else {
          this.paperFile.forEach((item) => {
            if (item.file_name[0] == file_name && item.size[0] == size) {
              this.repeatVisible = true;
            } else {
              this.paperFile.push(response.data);
              this.$message.success(response.message);
            }
          });
        }
      }
    },

    // 重复上传对话框
    repeatCon(num) {
      if (num == 0) {
        this.$message.error("取消上传");
      } else {
        this.$message.success("覆盖原文");
      }
      let removeItem = document.querySelectorAll(
        ".el-upload-list .el-upload-list__item"
      )[0];
      let parentItem = removeItem.parentNode;
      parentItem.removeChild(removeItem);
      this.repeatVisible = false;
    },

    // 预览
    paperPreview(file) {
      if (file.response) {
        window.open(file.response.data[0]);
      } else {
        window.open(file.url);
      }
    },

    // 删除文件
    removePaperFile(file, fileList) {
      this.paperFile = fileList.map((item) => {
        return { file_path: [item.url], file_name: [item.name] };
      });
    },

    // 上传之前格式限制
    beforePaperFile(file) {
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      if (type != "docx" && type != "pdf") {
        this.$message.error("上传文件的类型为word或者pdf");
        return false;
      }
    },

    // 发布说说
    async findInfo() {
      if (!this.talk) {
        this.$message({
          message: "发布内容不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          discover_id: this.id,
          type: 1,
          content: this.talk,
        };
      } else {
        data = {
          type: 1,
          content: this.talk,
        };
      }
      if (this.$route.query.from == "creation") {
        data.discover_id = this.id;
      }
      let arr = [];
      for (let i in this.talkImage) {
        arr.push(this.talkImage[i].url);
      }
      data.images = JSON.stringify(arr);

      if (this.userInfo.company && this.userInfo.company.status == 1) {
        this.isBtn = true;
        let res = await this.$findApi.findInfo(data);
        this.isBtn = false;
        if (res.code == 200) {
          this.talk = "";
          this.$message({
            message: "发布成功",
            type: "success",
          });
          // this.$router.push({path:'/found'})
          this.$router.push("/creation");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 病例分享
    async findInfo1() {
      if (!this.caseAnalysis) {
        this.$message({
          message: "请输入标题",
          type: "error",
        });
        return;
      }
      if (!this.caseSubtitle) {
        this.$message({
          message: "发布内容不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          discover_id: this.id,
          type: 2,
          title: this.caseAnalysis,
          content: this.caseSubtitle,
        };
      } else {
        data = {
          type: 2,
          title: this.caseAnalysis,
          content: this.caseSubtitle,
        };
      }
      if (this.$route.query.from == "creation") {
        data.discover_id = this.id;
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findInfo(data);
        if (res.code == 200) {
          this.caseAnalysis = "";
          this.caseSubtitle = "";
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.$router.push("/creation");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 学术视频
    async popularScience() {
      if (!this.popularTitle) {
        this.$message({
          message: "请输入标题",
          type: "error",
        });
        return;
      } else if (!this.popularVideo) {
        this.$message({
          message: "视频不能为空",
          type: "error",
        });
        return;
      } else if (!this.videoImg) {
        this.$message({
          message: "视频图片不能为空",
          type: "error",
        });
        return;
      } else if (!this.popularSubtitle) {
        this.$message({
          message: "发布内容不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          discover_id: this.id,
          type: 3,
          title: this.popularTitle,
          content: this.popularSubtitle,
          video: this.popularVideo,
          video_img: this.videoImg,
        };
      } else {
        data = {
          type: 3,
          title: this.popularTitle,
          content: this.popularSubtitle,
          video: this.popularVideo,
          video_img: this.videoImg,
        };
      }
      if (this.$route.query.from == "creation") {
        data.discover_id = this.id;
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findInfo(data);
        if (res.code == 200) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.$router.push("/creation");
        } else {
          this.$message.error(res.message);
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 视频文件大小设置
    onExceed() {},

    // 指南解析
    async entry() {
      let { disease, search } = this;
      if (!this.entryTitle) {
        this.$message({
          message: "请输入标题",
          type: "error",
        });
        return;
      }
      if (!this.guideSubtitle) {
        this.$message.error("请输入内容");
        return;
      }
      if (!disease) {
        this.$message({
          message: "疾病不能为空",
          type: "error",
        });
        return;
      }
      if (!search) {
        this.$message({
          message: "搜索词不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          discover_id: this.id,
          type: 4,
          title: this.entryTitle,
          content: this.guideSubtitle,
          disease,
          search,
        };
      } else {
        data = {
          type: 4,
          title: this.entryTitle,
          content: this.guideSubtitle,
          disease,
          search,
        };
      }
      if (this.$route.query.from == "creation") {
        data.discover_id = this.id;
      }

      if (this.annexFile) {
        data.file_path = [];
        data.file_name = [];
        this.annexFile.forEach((item) => {
          data.file_name.push(item.file_name[0]);
          data.file_path.push(item.file_path[0]);
        });
        data.file_name = data.file_name.join(",");
        data.file_path = data.file_path.join(",");
      }

      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findInfo(data);
        if (res.code == 200) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.$router.push("/creation");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    //发布问答
    async talkAbout() {
      if (!this.talkTitle) {
        this.$message({
          message: "标题不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          discover_id: this.id,
          type: 5,
          title: this.talkTitle,
          content: this.talkSubtitle,
        };
      } else {
        data = {
          type: 5,
          title: this.talkTitle,
          content: this.talkSubtitle,
        };
      }
      if (this.$route.query.from == "creation") {
        data.discover_id = this.id;
      }
      let arr = [];
      for (let i in this.talkImage) {
        arr.push(this.talkImage[i].url);
      }
      data.images = JSON.stringify(arr);
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findInfo(data);
        if (res.code == 200) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.$router.push("/creation");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 发布论文
    async releasePaper() {
      if (!this.paperTitle) {
        this.$message.error("标题不能为空");
        return;
      }
      if (!this.paperContent) {
        this.$message.error("内容不能为空");
        return;
      }
      if (!this.agreeRadio) {
        this.$message.error("请同意授权书");
        return;
      }
      if (!this.originalChecked) {
        this.$message.error("请选择作品原创声明");
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          discover_id: this.id,
          type: 11,
          title: this.paperTitle,
          content: this.paperContent,
          sqs: this.agreeRadio,
          ycsm: this.originalChecked,
        };
      } else {
        data = {
          type: 11,
          title: this.paperTitle,
          content: this.paperContent,
          sqs: this.agreeRadio,
          ycsm: this.originalChecked,
        };
      }
      if (this.$route.query.from == "creation") {
        data.discover_id = this.id;
      }

      if (this.paperFile) {
        data.file_path = [];
        data.file_name = [];
        this.paperFile.forEach((item) => {
          data.file_name.push(item.file_name[0]);
          data.file_path.push(item.file_path[0]);
        });
        data.file_name = data.file_name.join(",");
        data.file_path = data.file_path.join(",");
      }

      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findInfo(data);
        if (res.code == 200) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.$router.push("/creation");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    //删除操作
    handleRemove(ref, file) {
      this.$refs[ref].handleRemove(file);
      if (ref == "upload1") {
        for (let i in this.filelists1) {
          if (this.filelists1[i].uid == file.uid) {
            this.filelists1.splice(i, 1);
            break;
          }
        }
      } else if (ref == "upload3") {
        this.videoImg = "";
        this.filelists3 = [];
        setTimeout(() => {
          this.disStates = false;
        }, 1000);
      } else if (ref == "uploadVideo") {
        this.popularVideo = "";
        this.filevideo = [];
        setTimeout(() => {
          this.disState = false;
        }, 1000);
      } else if (ref == "upload5") {
        for (let i in this.filelists5) {
          if (this.filelists5[i].uid == file.uid) {
            this.filelists5.splice(i, 1);
            break;
          }
        }
      }

      for (let i in this.talkImage) {
        if (this.talkImage[i].uid == file.uid) {
          this.talkImage.splice(i, 1);
        }
      }
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
      this.imgView = file.url;
    },
    // 准备编辑器
    // onEditorReady(editor) {},
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
    handleClick(tab) {
      if (this.type != tab.label) {
        this.talkImage = [];
        this.type = tab.label;
      }
    },

    /********************************* 存入草稿箱 *******************************************/

    // 说说草稿箱
    async speakDraft() {
      if (!this.talk && !this.talkImage.length) {
        this.$message({
          message: "存入内容不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          id: this.id,
          type: 1,
          content: this.talk,
        };
      } else {
        data = {
          type: 1,
          content: this.talk,
        };
      }

      let arr = [];
      for (let i in this.talkImage) {
        arr.push(this.talkImage[i].url);
      }
      data.images = JSON.stringify(arr);

      if (this.userInfo.company && this.userInfo.company.status == 1) {
        this.isBtn = true;
        let res = await this.$findApi.findDiscoberReleaseDraft(data);
        this.isBtn = false;
        if (res.code == 200) {
          this.talk = "";
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.push("/personalCenter?index=1");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 病例分享草稿箱
    async caseAnalysisDraft() {
      if (!this.caseAnalysis && !this.caseSubtitle) {
        this.$message({
          message: "存入内容不能为空",
          type: "error",
        });
        return;
      }

      let data = "";
      if (!this.id) {
        data = {
          id: this.id,
          type: 2,
          title: this.caseAnalysis,
          content: this.caseSubtitle,
        };
      } else {
        data = {
          type: 2,
          title: this.caseAnalysis,
          content: this.caseSubtitle,
        };
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findDiscoberReleaseDraft(data);
        if (res.code == 200) {
          this.caseAnalysis = "";
          this.caseSubtitle = "";
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.push("/personalCenter?index=1");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 学术视频草稿箱
    async scienceDraft() {
      if (
        !this.popularTitle &&
        !this.popularVideo &&
        !this.videoImg &&
        !this.popularSubtitle
      ) {
        this.$message({
          message: "存入内容不能为空",
          type: "error",
        });
        return;
      }

      let data = "";
      if (!this.id) {
        data = {
          id: this.id,
          type: 3,
          title: this.popularTitle,
          content: this.popularSubtitle,
          video: this.popularVideo,
          video_img: this.videoImg,
        };
      } else {
        data = {
          type: 3,
          title: this.popularTitle,
          content: this.popularSubtitle,
          video: this.popularVideo,
          video_img: this.videoImg,
        };
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findDiscoberReleaseDraft(data);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.push("/personalCenter?index=1");
        } else {
          this.$message.error(res.message);
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 指南解析草稿箱
    async entryDraft() {
      let { disease, search } = this;
      if (!this.entryTitle && !this.guideSubtitle && !disease && !search) {
        this.$message({
          message: "存入内容不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          id: this.id,
          type: 4,
          title: this.entryTitle,
          content: this.guideSubtitle,
          disease,
          search,
        };
      } else {
        data = {
          type: 4,
          title: this.entryTitle,
          content: this.guideSubtitle,
          disease,
          search,
        };
      }
      if (this.annexFile) {
        data.file_path = [];
        data.file_name = [];
        this.annexFile.forEach((item) => {
          data.file_name.push(item.file_name[0]);
          data.file_path.push(item.file_path[0]);
        });
        data.file_name = data.file_name.join(",");
        data.file_path = data.file_path.join(",");
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findDiscoberReleaseDraft(data);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.push("/personalCenter?index=1");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 发布问答草稿箱
    async talkDarft() {
      if (!this.talkTitle && !this.talkImage.length) {
        this.$message({
          message: "存入内容不能为空",
          type: "error",
        });
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          id: this.id,
          type: 5,
          title: this.talkTitle,
          content: this.talkSubtitle,
        };
      } else {
        data = {
          type: 5,
          title: this.talkTitle,
          content: this.talkSubtitle,
        };
      }
      let arr = [];
      for (let i in this.talkImage) {
        arr.push(this.talkImage[i].url);
      }
      data.images = JSON.stringify(arr);
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findDiscoberReleaseDraft(data);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.push("/personalCenter?index=1");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 论文草稿箱
    async paperDarft() {
      if (!this.paperTitle && !this.paperContent && !this.paperFile.length) {
        this.$message.error("存入的内容不能为空");
        return;
      }
      let data = "";
      if (!this.id) {
        data = {
          id: this.id,
          type: 11,
          title: this.paperTitle,
          content: this.paperContent,
          sqs: this.agreeRadio,
          ycsm: this.originalChecked,
        };
      } else {
        data = {
          type: 11,
          title: this.paperTitle,
          content: this.paperContent,
          sqs: this.agreeRadio,
          ycsm: this.originalChecked,
        };
      }

      if (this.paperFile) {
        data.file_path = [];
        data.file_name = [];
        this.paperFile.forEach((item) => {
          data.file_name.push(item.file_name[0]);
          data.file_path.push(item.file_path[0]);
        });
        data.file_name = data.file_name.join(",");
        data.file_path = data.file_path.join(",");
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        let res = await this.$findApi.findDiscoberReleaseDraft(data);
        if (res.code == 200) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.$router.push("/personalCenter?index=1");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.dialogVisibleTwo = true;
      }
    },

    // 草稿箱详情
    async draftInfo() {
      let id = this.$route.query.id;
      let type = this.$route.query.type;
      let res = await this.$findApi.findDiscobergetDraftInfo({
        id,
      });
      if (res.code == 200) {
        let imgs = res.data.images ? JSON.parse(res.data.images) : [];
        if (type == 1) {
          this.talk = res.data.content;
          for (let i in imgs) {
            let list = {
              url: imgs[i],
            };
            this.filelists1.push(list);
            this.talkImage.push(list);
          }
          if (this.talkImage.length) {
            this.display1 = "block";
          }
        } else if (type == 2) {
          this.caseAnalysis = res.data.title;
          this.caseSubtitle = res.data.content;
        } else if (type == 3) {
          this.popularTitle = res.data.title;
          this.popularSubtitle = res.data.content;
          // 视频封面
          if (res.data.video_img) {
            this.filelists3 = [
              {
                url: res.data.video_img,
              },
            ];
            this.videoImg = res.data.video_img;
            this.disStates = true;
          }

          // 视频
          if (res.data.video) {
            this.filevideo = [
              {
                url: res.data.video,
              },
            ];
            this.popularVideo = res.data.video;
            // this.howLong();
            this.disState = true;
          }
        } else if (type == 4) {
          this.entryTitle = res.data.title;
          this.guideSubtitle = res.data.content;
          this.disease = res.data.disease;
          this.search = res.data.search;
          // 上传附件回显
          let file_path = res.data.file_path_new;
          if (file_path) {
            Object.keys(file_path).forEach((key) => {
              this.annexFile.push({
                file_path: [key],
                file_name: [file_path[key]],
              });
              this.annexFileList.push({
                url: key,
                name: file_path[key],
              });
            });
          }
        } else if (type == 5) {
          this.talkTitle = res.data.title;
          this.talkSubtitle = res.data.content;
          for (let i in imgs) {
            let list = {
              url: imgs[i],
            };
            this.filelists5.push(list);
            this.talkImage.push(list);
          }
          if (this.talkImage.length > 0) {
            this.display2 = "block";
          }
        } else if (type == 11) {
          this.paperTitle = res.data.title;
          this.paperContent = res.data.content;
          // 上传附件回显
          let file_path = res.data.file_path_new;
          if (file_path) {
            Object.keys(file_path).forEach((key) => {
              this.paperFile.push({
                file_path: [key],
                file_name: [file_path[key]],
              });
              this.paperFileList.push({
                url: key,
                name: file_path[key],
              });
            });
          }
        }
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>


<style>
.hide .el-upload--picture-card {
  display: none !important;
}
</style>


<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background: #f2f2f2;

  > section {
    display: flex;
    flex-direction: column;

    > .title {
      margin: 30px 0 22px 0;
      font-size: 18px;
      font-family: PingFang HK;
      font-weight: 600;
      line-height: 25px;
      color: #333333;
    }

    > .content {
      width: 1200px;
      background: #ffffff;
      position: relative;

      .drafts {
        position: absolute;
        right: 40px;
        top: 15px;
        font-size: 14px;
        cursor: pointer;
        z-index: 10;

        img {
          width: 25px;
          vertical-align: sub;
        }
      }

      .btn {
        margin-left: 800px;

        .plain {
          color: $--themeColor;
          border-color: $--themeColor;
        }
      }

      .button {
        cursor: pointer;
        width: 140px;
        height: 40px;
        object-fit: cover;
        margin-top: 35px;
      }

      .richText {
        width: 1128px;
        margin: 0 auto;

        ::v-deep {
          .ql-editor {
            // color:#8F8F8F;
            font-size: 16px;
            color: #333;
          }

          .ql-toolbar.ql-snow + .ql-container.ql-snow {
            // min-height: 300px;
            border: none;
            margin: 0 auto;
            height: 149px;
            background-color: #fff;
            margin-top: 20px;
            width: 100%;
          }

          .ql-toolbar.ql-snow {
            border: none;
            background-color: #fff;
            height: 71px;
            padding-top: 10.3px;
          }
        }
      }

      #pane-tab4 {
        .richText {
          width: 1128px;
          margin: 0 auto;

          ::v-deep {
            .ql-toolbar.ql-snow + .ql-container.ql-snow {
              border: none;
              margin: 0 auto;
              height: 149px;
              background: #fafafa;
              margin: 20px 0;
              width: 100%;
            }

            .ql-toolbar.ql-snow {
              border: none;
              background: #fff;
              height: 71px;
              padding-top: 10.3px;
            }
          }
        }
      }

      #pane-tab11 {
        .richText {
          width: 1128px;
          margin: 0 auto;

          ::v-deep {
            .ql-toolbar.ql-snow + .ql-container.ql-snow {
              border: none;
              margin: 0 auto;
              height: 149px;
              background: #fafafa;
              margin: 20px 0;
              width: 100%;
            }

            .ql-toolbar.ql-snow {
              border: none;
              background: #fff;
              height: 71px;
              padding-top: 10.3px;
            }
          }
        }
      }
      .yourAnswer {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(5) {
          margin-top: 20px;
        }

        &:nth-child(2) {
          margin-top: 20px;
        }

        .answer {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #333333;
          font-size: 24px;
          position: relative;

          > .annotation {
            color: $--themeColor;
            font-size: 14px;
            margin-left: 10px;
            margin-top: 10px;
          }

          .upload_img {
            width: 40px;
            cursor: pointer;
            margin-left: 20px;
          }
        }

        .earnings {
          display: flex;
          flex-direction: row;
          color: #8f8f8f;
          font-size: 18px;
          margin-top: 20px;

          > .money {
            color: #e60012;
          }
        }

        > .button {
          margin-top: 18px;
        }
      }

      .bottomBox {
        display: flex;
        flex-direction: row;
        margin-top: 7px;
        // margin-bottom: 70px;

        ::v-deep {
          .el-upload-list--picture-card .el-upload-list__item {
            width: 150px;
            height: 150px;
            object-fit: cover;
            margin-right: 20px;
            margin-bottom: 20px;

            &:first-child {
              margin-left: 0;
            }
          }

          .el-upload--picture-card {
            line-height: 0;
            display: flex;
            flex-direction: column;
            color: #8f8f8f;
            // background: #ffffff;
            // border: 1px solid #d9d9d9;
            background: #f2f2f2;
            border: 2px solid #d9d9d9;
            opacity: 1;
            border-radius: 10px;

            > i {
              margin: 55px 0 30px 0;
            }

            &:hover {
              color: $--themeColor;
              border-color: $--themeColor;

              > i {
                color: $--themeColor;
              }

              span {
                color: $--themeColor;
              }
            }
          }

          .el-upload--picture-card .el-upload-list__item img {
            width: 150px;
            height: 150px;
            object-fit: cover;
          }

          .el-upload {
            width: 150px;
            height: 150px;
            display: inline-flex;
            margin-bottom: 20px;

            > span {
              margin-top: 10px;
            }
          }
        }

        > .tooltip {
          display: flex;
          flex-direction: column;
          width: 500px;
          height: 150px;
          border-radius: 10px;
          margin-left: 150px;
          padding: 30px;
          box-sizing: border-box;
          font-size: 14px;
          color: #8f8f8f;
          background: #f0f0f0;
          opacity: 1;
          border-radius: 10px;

          > span {
            &:last-child {
              margin-bottom: 0;
            }

            margin-bottom: 10px;
            margin-left: 24px;
          }

          > .tooltipOne {
            display: flex;
            flex-direction: row;

            > span {
              margin-bottom: 10px;
            }

            > .question {
              width: 14px;
              height: 14px;
              object-fit: cover;
              margin-right: 10px;
            }

            > .close {
              cursor: pointer;
              margin-left: auto;
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .authorizationBottom {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        > .authorization {
          &:last-child {
            margin-right: 0;
          }

          display: flex;
          flex-direction: row;
          margin-top: 25px;
          font-size: 24px;
          color: #333333;
          margin-right: 50px;

          > .weixuanzhong {
            color: #333333;
          }

          > .iconfont {
            margin-right: 10px;
            text-align: center;
            font-size: 24px;
          }

          > .xuanzhong {
            color: $--themeColor;
          }
        }
      }
    }
  }
}

.videoDel-box:hover {
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;

  .videoDel {
    color: #fff;
  }
}

.videoDel-box {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  width: 34px;
  height: 34px;

  display: flex;
  align-items: center;
  justify-content: center;

  .videoDel {
    color: #000;
    font-size: 20px;
  }
}

// 发布说说
.postTalkInput {
  padding: 20px;

  // box-sizing: border-box
  ::v-deep {
    .el-textarea__inner {
      padding: 0;
      resize: none;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 30px;
      opacity: 1;
      padding: 20px 15px;
      color: #000;
    }
  }
}

.describe {
  width: 1100px;

  /deep/.el-textarea__inner {
    font-size: 14px;
    width: 1100px;
    resize: none;
    height: 106px !important;
    font-size: 16px;
    color: #333;
    margin-top: 12px;
    margin-left: 20px;
  }

  margin-bottom: 22px;
}

// 上传视频
.popularScienceVideo {
  margin-top: 22px;
  width: 1100px;
  margin-left: 20px;

  ::v-deep {
    .el-textarea__inner {
      resize: none;
      height: 96px;
      font-size: 16px;
      color: #333;
    }
  }
}

/deep/.el-upload--picture-card {
  // border:1px solid #c0ccda;
  border: 2px solid #d9d9d9;
}

.illness {
  ::v-deep {
    .el-input__inner {
      // background: #fafafa;
      height: 50px;
      border: none;
      font-size: 16px;
      color: #333;
    }

    .el-input__inner {
      border: none !important;
      margin-top: 20px !important;
      font-size: 16px !important;
      padding-left: 20px !important;
      color: #333;
    }
  }
}

.popularScienceEntry-import {
  width: 1090px;
  outline: none;
  height: 200px;

  /deep/.el-textarea__inner {
    background: #fafafa;
    margin-top: 20px;
    resize: none;
    margin-left: 20px;
    height: 200px;
    color: #333;
  }
}

// 学术视频
.videoOne {
  display: flex;
  flex-direction: row;
  margin-left: 20px;

  .videoImg {
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }

    ::v-deep {
      .el-upload-list__item .is-success {
        div {
          width: 310px;
          height: 150px;
        }

        img {
          width: 310px;
          height: 150px;
          object-fit: cover;
          background-repeat: repeat;
        }
      }

      .el-upload-list__item-actions {
        width: 310px;
        height: 150px;
      }

      > .el-upload {
        display: flex;
        flex-direction: column;
        width: 310px;
        height: 150px;
        background: #f2f2f2;
        margin: 0;
        margin-top: 19px;
        color: #8f8f8f;

        > span {
          line-height: 30px;
          margin-top: 30px;
          font-size: 14px;
        }
      }

      > .el-upload--picture-card {
        display: flex;
        flex-direction: column;

        > i {
          margin-top: 50px;
        }

        &:hover {
          border-color: $--themeColor;
          color: $--themeColor;

          > i {
            color: $--themeColor;
          }
        }

        line-height: 0;
      }

      > .el-upload-list {
        > .el-upload-list__item {
          width: 310px;
          height: 150px;
          margin-left: 20px;
          margin-top: 20px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 20px;
          }
        }
      }
    }

    .el-icon-plus::before {
      width: 39px;
      height: 39px;
    }
  }

  .video_name {
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }

    ::v-deep {
      > .el-upload {
        display: flex;
        flex-direction: column;
        width: 310px;
        height: 150px !important;
        background: #f2f2f2;
        margin: 0;
        margin-top: 19px;
        color: #8f8f8f;

        > span {
          line-height: 30px;
          margin-top: 30px;
          font-size: 14px;
        }
      }

      > .el-upload--picture-card {
        display: flex;
        flex-direction: column;

        > i {
          margin-top: 50px;
        }

        &:hover {
          border-color: $--themeColor;
          color: $--themeColor;

          > i {
            color: $--themeColor;
          }

          > span {
            color: $--themeColor;
          }
        }

        line-height: 0;
      }

      > .el-upload-list {
        > .el-upload-list__item {
          width: 310px;
          height: 150px !important;
          margin-left: 20px;
          margin-top: 20px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 20px;
          }
        }
      }
    }

    .el-icon-plus::before {
      width: 39px;
      height: 39px;
    }
  }
}

.fabu {
  margin-left: 20px;

  ::v-deep {
    .el-upload-list--picture-card .el-upload-list__item {
      width: 150px;
      height: 150px;
      object-fit: cover;
      margin: 0;
      border: none;
      margin-left: 20px;
      margin-bottom: 20px;

      &:first-child {
        margin-left: 0;
      }

      &:nth-child(7) {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 20px;
      }
    }

    .el-upload-list__item {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }

    .el-upload-list__item-thumbnail {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }

    .el-upload {
      background: #f2f2f2;
      border: 2px solid #d9d9d9;
      opacity: 1;
      border-radius: 10px;
      width: 150px;
      height: 150px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #8f8f8f;
      margin-bottom: 20px;
      position: relative;

      &:hover {
        color: $--themeColor;
        border-color: $--themeColor;

        .iconfont {
          color: $--themeColor;
        }

        span {
          color: $--themeColor;
        }
      }

      > .iconfont {
        // line-height: 30px;
        // height: max-content;
        // height:50px;
        // width:50px;
        font-size: 50px;
        position: absolute;
        top: -20px;
        left: 50px;
      }

      > span {
        height: max-content;
        position: absolute;
        top: 100px;

        font-size: 14px;
        // position:absolute;
        // top:120px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #8f8f8f;
        display: block;
        opacity: 1;
        left: 33px;
      }

      > span :hover {
        color: #03dbc6;
      }
    }
  }
}

::v-deep {
  .text-container {
    width: 100% !important;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    padding: 70px 80px;
    box-sizing: border-box;
    margin-top: 320px;

    > .el-dialog__body {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .popupsButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      > .popupsJudgment {
        &:last-child {
          background: $--themeColor;
          border: none;
          color: #ffffff;
        }

        width: 170px;
        height: 50px;
        background: #ffffff;
        border: 1px solid $--themeColor;
        border-radius: 26px;
        color: $--themeColor;
        font-size: 18px;
        text-align: center;
        line-height: 50px;
        margin-top: 60px;
      }
    }
  }

  .el-carousel__item .banner {
    width: 890px;
    height: 400px;
    object-fit: cover;
  }

  .el-carousel__arrow {
    display: none;
  }
}

::v-deep {
  .el-tabs__content {
    // padding: 50px 30px 20px 50px;
    box-sizing: border-box;
    margin: 26px 30px 0 30px;
    // padding:20px;

    width: 1140px;
    // height: 475px;
    background: #fff;
    opacity: 1;
    border-radius: 10px;
    overflow: visible;
  }

  .el-input {
    background: none !important;

    .el-input__inner {
      width: 1080px;
      border: none;
      border-bottom: 2px solid $--themeColor;
      font-size: 24px;
      background-color: #fafafa;
      color: #8f8f8f;
      margin-top: 14px;
      margin-bottom: 10px;
      margin-left: 20px;
      padding: 0;
      color: #333;
    }
  }

  .el-input {
    width: 240px;
    // height: 40px;
    background: #f2f2f2;

    border-radius: 0;
  }
}

::v-deep .el-textarea__inner {
  border: 0;
  box-sizing: border-box;
  font-size: 20px;
  // color: #8f8f8f;
  color: #333;

  // margin-top: 40px;
}

::v-deep .el-tabs__header {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding-left: 20px;
    height: 59px;

    .el-tabs__nav {
      height: 100%;
      line-height: 59px;
    }

    .el-tabs__item {
      font-weight: 0;
      color: #333333;
    }

    .is-active {
      color: $--themeColor;
      transform: scale(1);
      font-weight: 0;
    }

    .el-tabs__header {
      font-size: 24px;
    }
  }
}

/deep/#tab-tab1,
/deep/#tab-tab2,
/deep/#tab-tab3,
/deep/#tab-tab4,
/deep/#tab-tab5,
/deep/#tab-tab11 {
  font-weight: 700;
}

.postTalkInput /deep/textarea {
  height: 162px;
  background-color: #fff;
}

.speakDiv {
  background-color: #fafafa;
  padding-bottom: 20px;
  width: 1140px;

  .desc {
    font-size: 16px;
    background: #f4f4f4;
    padding: 30px;
    border-radius: 10px;
    line-height: 28px;
    color: #8f8f8f;
    margin: 0 20px;
    height: 90px;

    .close {
      position: absolute;
      right: 20px;
      top: 15px;
      width: 15px;
      cursor: pointer;
    }

    img {
      width: 22px;
      vertical-align: sub;
    }
  }
}

.desc1 {
  font-size: 16px;
  background: #f4f4f4;
  padding: 30px;
  border-radius: 10px;
  line-height: 28px;
  color: #8f8f8f;
  margin: 20px;

  .close {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 15px;
    cursor: pointer;
  }

  img {
    width: 22px;
    vertical-align: sub;
  }
}

.caseDiv {
  background-color: #fafafa;
  padding-bottom: 30px;

  .desc {
    position: relative;
    font-size: 16px;
    background: #f4f4f4;
    padding: 30px;
    border-radius: 10px;
    line-height: 28px;
    color: #8f8f8f;
    margin: 30px 20px;

    .close {
      position: absolute;
      right: 20px;
      top: 15px;
      width: 15px;
      cursor: pointer;
    }

    img {
      width: 22px;
      vertical-align: sub;
    }
  }
}

/deep/#pane-tab5 {
  // height: 374px;
  padding-bottom: 30px;
}

/deep/#pane-tab4 {
  // height: 916px !important;
  background-color: #fff;
  padding-bottom: 20px;

  ::v-deep {
    .el-tabs__content {
      // padding: 50px 30px 20px 50px;
      box-sizing: border-box;
      margin: 26px 30px 0 30px;

      // padding:20px;

      width: 1140px;
      height: 916px;
      background: red !important;
      opacity: 1;
      border-radius: 10px;

      .el-tab-pane {
        height: 916px;
        padding: 0 20px;
        // width:1100px;
        background-color: red !important;
      }
    }

    .el-input {
      background: none !important;

      .el-input__inner {
        width: 1080px;
        border: none;
        border-bottom: 2px solid $--themeColor;
        font-size: 24px;
        background-color: #fff;
        color: #333;
        margin-top: 14px;
        margin-bottom: 10px;
      }
    }

    .el-input {
      width: 240px;
      // height: 40px;
      background: #f2f2f2;

      border-radius: 0;
    }
  }

  ::v-deep .el-textarea__inner {
    border: 0;
    box-sizing: border-box;
    font-size: 20px;
    color: #333;

    // margin-top: 40px;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;

    .el-tabs__nav-scroll {
      padding-left: 20px;
      height: 59px;

      .el-tabs__nav {
        height: 100%;
        line-height: 59px;
      }

      .el-tabs__item {
        font-weight: 0;
        color: #333333;
      }

      .is-active {
        color: $--themeColor;
        transform: scale(1);
        font-weight: 0;
      }

      .el-tabs__header {
        font-size: 24px;
      }
    }
  }
}

/deep/#pane-tab1 {
  background-color: #fff;
  padding-bottom: 30px;

  ::v-deep {
    .el-tabs__content {
      box-sizing: border-box;
      margin: 26px 30px 0 30px;
      width: 1140px;
      height: 475px !important;
      background: red !important;
      opacity: 1;
      border-radius: 10px;

      .el-tab-pane {
        padding: 0 20px;
        background-color: red !important;
      }
    }
  }
}

/deep/#pane-tab2 {
  padding-bottom: 30px;
  background-color: #fff;

  ::v-deep {
    .el-tabs__content {
      box-sizing: border-box;
      margin: 26px 30px 0 30px;
      width: 1140px;
      height: 475px !important;
      background: red !important;
      opacity: 1;
      border-radius: 10px;

      .el-tab-pane {
        width: 1140px;
        background-color: red !important;
      }
    }
  }
}

.caseAnalysis .el-input__inner {
  margin-top: 14px;
  padding-left: 20px;
  color: #333;
}

/deep/#pane-tab3 {
  padding-bottom: 30px;
  background-color: #fff;

  ::v-deep {
    .el-tabs__content {
      box-sizing: border-box;
      margin: 26px 30px 0 30px;
      width: 1140px;
      opacity: 1;
      border-radius: 10px;

      .el-tab-pane {
        width: 1140px;
      }
    }
  }
}

/deep/#pane-tab5 {
  background-color: #fff;

  ::v-deep {
    .el-tabs__content {
      box-sizing: border-box;
      margin: 26px 30px 0 30px;
      width: 1140px;
      opacity: 1;
      border-radius: 10px;

      .el-tab-pane {
        width: 1140px;
      }
    }
  }
}

.caseAnalysis .el-input__inner {
  margin-top: 14px;
  padding-left: 20px;
  color: #333;
}

.guide-title /deep/.el-input__inner {
  background-color: #fff;
  color: #333;
}

/deep/.el-upload:hover {
  color: red;
}

/deep/ .paperTextarea textarea {
  height: 100%;
}
.uploadaAnnex {
  padding: 0 30px 10px 0;
  margin: 0 10px;
  border-bottom: 1px solid #dedede;
}

.agreement {
  padding: 10px 20px;
  span {
    margin-right: 20px;
  }
  /deep/ .el-radio .el-radio__input .el-radio__inner {
    width: 20px;
    height: 20px;
  }
  /deep/ .el-radio .el-radio__input .el-radio__inner::after {
    width: 8px;
    height: 8px;
  }
}
.original {
  padding: 80px 30px 20px;
  text-align: center;

  /deep/ .el-checkbox__label {
    font-size: 18px;
  }

  /deep/ .el-checkbox .el-checkbox__input .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-color: $--themeColor;
  }
  /deep/ .el-checkbox .el-checkbox__input .el-checkbox__inner::after {
    height: 12px;
    left: 7px;
    border-width: 2px;
  }
}
.paperBtn {
  background: #fff;
  margin-left: 0 !important;
  padding-left: 800px;
  padding-bottom: 20px;
}
.empower {
  /deep/.el-dialog {
    border-radius: 6px;
    padding: 20px 30px;
    min-height: 300px;
  }

  img {
    position: absolute;
    width: 25px;
    right: -15px;
    top: -10px;
    cursor: pointer;
  }

  h4 {
    font-size: 20px;
    text-align: left;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #eaeaea;
  }

  .word {
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    padding: 10px 0;
  }
}
.original {
  /deep/.el-dialog {
    border-radius: 6px;
    padding: 20px 30px;
    min-height: 300px;
  }

  img {
    position: absolute;
    width: 25px;
    right: -15px;
    top: -10px;
    cursor: pointer;
  }

  h4 {
    font-size: 20px;
    text-align: left;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #eaeaea;
  }

  .word {
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    padding: 10px 0;
  }
}

/deep/ .repeat_confirm {
  .el-dialog {
    padding: 30px;

    > .el-dialog__body {
      font-size: 18px;
    }

    > .el-dialog__footer {
      margin-top: 20px;
      padding: 0;

      .el-button {
        padding: 0;
        height: 30px;
        line-height: 30px;
        width: 50px;
      }
    }
  }
}
</style>
